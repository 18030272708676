// eslint-disable-next-line no-restricted-imports
import { Arguments, MutatorOptions, mutate } from "swr"

function mutateData(
    key: Arguments,
    data?: unknown,
    opts?: boolean | MutatorOptions<any> | undefined
) {
    return mutate(key, data, opts)
}

export default mutateData
