/**
 * Function to check if a URL is non-navigational
 * @param href URL to check
 * @returns boolean indicating whether the URL is non-navigational
 */
export function isNonNavigational(href: string) {
  if (!href) {
    return false
  }
  return ["#", "?", "mailto:", "tel:"].some((s) => href.startsWith(s))
}
