export default function PregnancyOracleCalendar() {
    return (
        <svg
            viewBox="0 0 61 61"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M48.2917 15.25H12.7084V20.3333H48.2917V15.25Z"
                fill="white"
            />
            <path
                d="M48.2917 10.1667H45.75V5.08333H40.6667V10.1667H20.3333V5.08333H15.25V10.1667H12.7083C9.88708 10.1667 7.65042 12.4542 7.65042 15.25L7.625 50.8333C7.625 53.6292 9.88708 55.9167 12.7083 55.9167H48.2917C51.0875 55.9167 53.375 53.6292 53.375 50.8333V15.25C53.375 12.4542 51.0875 10.1667 48.2917 10.1667ZM48.2917 50.8333H12.7083V25.4167H48.2917V50.8333ZM48.2917 20.3333H12.7083V15.25H48.2917V20.3333ZM22.875 35.5833H17.7917V30.5H22.875V35.5833ZM33.0417 35.5833H27.9583V30.5H33.0417V35.5833ZM43.2083 35.5833H38.125V30.5H43.2083V35.5833ZM22.875 45.75H17.7917V40.6667H22.875V45.75ZM33.0417 45.75H27.9583V40.6667H33.0417V45.75ZM43.2083 45.75H38.125V40.6667H43.2083V45.75Z"
                fill="black"
            />
        </svg>
    )
}
