export default function PregnancyOracleStroller() {
    return (
        <svg
            viewBox="0 0 61 61"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M38.125 11.0304V20.3329H47.4275C45.8771 16.0121 42.4458 12.5809 38.125 11.0304ZM20.435 36.4979L21.5533 38.2009C24.5779 38.6075 27.1196 40.5392 28.365 43.2079H35.1766C36.6 40.2088 39.6754 38.1246 43.2083 38.1246C43.5896 38.1246 43.9962 38.15 44.3775 38.2009L45.1146 37.2604C46.1312 35.9642 46.8937 34.5409 47.4529 33.0413H18.7321C19.5454 34.7442 20.1808 36.0659 20.435 36.4979V36.4979Z"
                fill="white"
            />
            <path
                d="M33.0417 5.08334V25.4167H53.375C53.375 14.1825 44.2759 5.08334 33.0417 5.08334ZM38.125 20.3333V11.0308C42.4459 12.5558 45.8771 15.9871 47.4275 20.3333H38.125ZM16.3684 27.9583L13.9538 22.875H5.08337V27.9583H10.7259C10.7259 27.9583 15.5296 38.3029 16.1142 39.1925C13.3184 40.6921 11.4375 43.6404 11.4375 47.0208C11.4375 51.9263 15.428 55.9167 20.3334 55.9167C24.8067 55.9167 28.5175 52.6125 29.1275 48.2917H34.4142C35.0242 52.6125 38.735 55.9167 43.2084 55.9167C48.1138 55.9167 52.1042 51.9263 52.1042 47.0208C52.1042 44.3775 50.935 42.0138 49.105 40.3871C51.7738 36.9558 53.375 32.635 53.375 27.9583H16.3684V27.9583ZM20.3334 50.8333C18.2238 50.8333 16.5209 49.1304 16.5209 47.0208C16.5209 44.9113 18.2238 43.2083 20.3334 43.2083C22.443 43.2083 24.1459 44.9113 24.1459 47.0208C24.1459 49.1304 22.443 50.8333 20.3334 50.8333ZM43.2084 50.8333C41.0988 50.8333 39.3959 49.1304 39.3959 47.0208C39.3959 44.9113 41.0988 43.2083 43.2084 43.2083C45.318 43.2083 47.0209 44.9113 47.0209 47.0208C47.0209 49.1304 45.318 50.8333 43.2084 50.8333ZM45.0892 37.2608L44.3521 38.2013C43.9963 38.1504 43.5896 38.125 43.2084 38.125C39.6755 38.125 36.6 40.2092 35.1767 43.2083H28.365C27.0942 40.565 24.5525 38.6333 21.5534 38.2013L20.435 36.4983C20.1809 36.0663 19.5709 34.7446 18.7321 33.0417H47.4275C46.8938 34.5413 46.1059 35.9646 45.0892 37.2608Z"
                fill="black"
            />
        </svg>
    )
}
