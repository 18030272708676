export default function Quiz() {
    return (
        <svg
            aria-label="Quiz"
            viewBox="0 0 139 92"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                opacity={0.3}
                d="M99.3447 82.7023C109.179 82.8151 119.867 84.6026 128.181 79.2846C137.382 73.4163 140.117 60.7741 138.61 49.8441C136.356 33.3432 126.041 18.1855 111.641 10.1982C104.347 6.15847 96.2192 3.92668 88.0909 2.22123C80.548 0.638811 72.8201 -0.51297 65.1561 0.232099C48.3343 1.88971 33.7969 12.4574 17.8128 18.0249C14.8017 19.0741 11.6729 19.9764 9.08231 21.8493C3.96176 25.5473 1.93976 32.2427 0.906905 38.5416C-0.906483 49.5946 -0.415247 61.8404 6.24618 70.771C9.23036 74.7697 13.2575 77.832 17.325 80.6824C22.1528 84.066 27.2229 87.2753 32.8818 88.8269C40.9562 91.0382 49.5219 89.6882 57.778 88.3279C71.4037 86.0859 85.4835 82.5178 99.3379 82.6852"
                fill="currentColor"
            />
            <g>
                <g>
                    <g>
                        <g>
                            <path
                                d="M74.8472 66.6428C72.2595 66.6428 70.3187 64.7018 70.3187 62.1139C70.3187 59.5259 72.2595 57.5849 74.8472 57.5849C77.4348 57.5849 79.3756 59.5259 79.3756 62.1139C79.3756 65.0253 77.1114 66.6428 74.8472 66.6428ZM85.5213 40.1162C82.9337 43.9981 80.346 45.2921 78.7287 47.5566C78.4052 48.8505 78.0818 49.4975 78.0818 52.409H71.6126C71.6126 50.468 71.2891 47.8801 72.9064 45.2921C74.8472 42.3806 77.7583 40.4397 79.6991 37.8517C81.9633 35.2637 80.9929 29.7643 75.1706 29.7643C71.2891 29.7643 69.3483 32.6758 68.7014 34.9402L62.8792 32.6758C64.173 28.1468 68.7014 23.9414 74.8472 23.9414C80.0225 23.9414 83.5806 26.2059 85.5213 29.1173C87.1386 31.7053 87.7856 36.8812 85.5213 40.1162Z"
                                fill="black"
                            />
                        </g>
                    </g>
                    <g>
                        <path
                            d="M94.2547 74.4069H55.4396C49.9408 74.4069 45.7358 70.2015 45.7358 64.702V25.8826C45.7358 20.3832 49.9408 16.1777 55.4396 16.1777H94.2547C99.7536 16.1777 103.959 20.3832 103.959 25.8826V64.702C103.959 70.2015 99.7536 74.4069 94.2547 74.4069Z"
                            stroke="black"
                            strokeWidth={2}
                            strokeMiterlimit={10}
                        />
                    </g>
                    <g>
                        <path
                            d="M92.6374 75.7006C92.6374 76.3476 91.667 79.906 89.7263 81.847C87.1386 84.435 83.5806 84.7585 82.9336 84.7585H44.1185C38.6196 84.7585 34.4147 80.553 34.4147 75.0536V36.2342C34.4147 30.7347 37.3258 26.5293 42.8246 26.5293"
                            stroke="black"
                            strokeWidth={2}
                            strokeMiterlimit={10}
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}
