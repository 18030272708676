export const MAX_SUGGESTION_CHAR_LENGTH = 50
export const MAX_WORD_COUNT = 10
const MIN_QUERY_LENGTH = 2

/**
 * Normalization of a string query.
 * Workaround since the input is not sanitized at the backend, and the Search API doesn't handle certain characters (should be fixed)
 * @param query  Search query
 * @returns a sanitized version of the query limited to 30 characters and 10 separate words.
 */

export function normalizeQuery(
  query: string | string[],
  variant: "search" | "suggestion" = "search"
) {
  let q = Array.isArray(query) ? query.join(" ") : query

  if (q === "") return ""

  if (q.split(" ").length > MAX_WORD_COUNT)
    q = q.split(" ").slice(0, MAX_WORD_COUNT).join(" ")

  if (variant === "suggestion" && q.length > MAX_SUGGESTION_CHAR_LENGTH)
    q = q.slice(0, MAX_SUGGESTION_CHAR_LENGTH)

  return q
}

/**
 * Checks if a search query is valid. It is valid if it has input and the normalized query has more than 0 characters
 * @param query Search query as string
 * @returns
 */
export function isQueryValid(
  query: string,
  variant: "search" | "suggestion" = "search"
) {
  if (!query) {
    return false
  }
  const normalized = normalizeQuery(query, variant)

  if (
    !normalized ||
    normalized.length < MIN_QUERY_LENGTH ||
    !normalized.match(/\p{L}|\d/iu)
  ) {
    return false
  }
  return true
}
