export default function PregnancyOracleClock() {
    return (
        <svg
            viewBox="0 0 61 61"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M30.5 15.25C20.6892 15.25 12.7084 23.2308 12.7084 33.0417C12.7084 42.8525 20.6892 50.8333 30.5 50.8333C40.3109 50.8333 48.2917 42.8525 48.2917 33.0417C48.2917 23.2308 40.3109 15.25 30.5 15.25ZM26.7638 42.2933L18.6813 34.2108L21.3755 31.5167L26.7892 36.9304L39.3196 24.3492L42.0138 27.0433L26.7638 42.2933V42.2933Z"
                fill="white"
            />
            <path
                d="M26.7891 36.9304L21.3754 31.5167L18.6812 34.2108L26.7637 42.2933L42.0137 27.0433L39.3195 24.3492L26.7891 36.9304ZM44.0648 4.60042L55.7743 14.3731L52.5209 18.2746L40.8039 8.50696L44.0648 4.60042ZM16.9351 4.60042L20.1935 8.50442L8.48148 18.2746L5.22815 14.3706L16.9351 4.60042ZM30.4999 10.1667C17.8679 10.1667 7.62494 20.4096 7.62494 33.0417C7.62494 45.6737 17.8679 55.9167 30.4999 55.9167C43.132 55.9167 53.3749 45.6737 53.3749 33.0417C53.3749 20.4096 43.132 10.1667 30.4999 10.1667ZM30.4999 50.8333C20.6891 50.8333 12.7083 42.8525 12.7083 33.0417C12.7083 23.2308 20.6891 15.25 30.4999 15.25C40.3108 15.25 48.2916 23.2308 48.2916 33.0417C48.2916 42.8525 40.3108 50.8333 30.4999 50.8333Z"
                fill="black"
            />
        </svg>
    )
}
