import AccessAlarmOutlinedIcon from "@mui/icons-material/AccessAlarmOutlined"
import AddIcon from "@mui/icons-material/Add"
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import ArrowUpward from "@mui/icons-material/ArrowUpward"
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined"
import BugReportIcon from "@mui/icons-material/BugReport"
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined"
import CheckIcon from "@mui/icons-material/Check"
import ChildFriendlyOutlinedIcon from "@mui/icons-material/ChildFriendlyOutlined"
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined"
import ClearIcon from "@mui/icons-material/Clear"
import CloseIcon from "@mui/icons-material/Close"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import CodeIcon from "@mui/icons-material/Code"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined"
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import ExpandMore from "@mui/icons-material/ExpandMore"
import HelpOutlinedIcon from "@mui/icons-material/HelpOutlined"
import HomeIcon from "@mui/icons-material/Home"
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined"
import InfoIcon from "@mui/icons-material/Info"
import LanguageIcon from "@mui/icons-material/Language"
import LaunchIcon from "@mui/icons-material/Launch"
import Link from "@mui/icons-material/Link"
import LocalOfferIcon from "@mui/icons-material/LocalOffer"
import MenuIcon from "@mui/icons-material/Menu"
import PauseCircleOutlineOutlinedIcon from "@mui/icons-material/PauseCircleOutlineOutlined"
import PictureAsPdf from "@mui/icons-material/PictureAsPdf"
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined"
import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined"
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined"
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined"
import SearchIcon from "@mui/icons-material/Search"
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications"
import StorageIcon from "@mui/icons-material/Storage"
import UpdateOutlinedIcon from "@mui/icons-material/UpdateOutlined"
import WarningIcon from "@mui/icons-material/Warning"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

import Disease from "Components/Icon/SvgIcons/Disease"
import Medikit from "Components/Icon/SvgIcons/Medikit"
import PregnancyOracle from "Components/Icon/SvgIcons/PregnancyOracle"
import Quiz from "Components/Icon/SvgIcons/Quiz"
import Start from "Components/Icon/SvgIcons/Start"
import Stethoscope from "Components/Icon/SvgIcons/Stethoscope"
import Stop from "Components/Icon/SvgIcons/Stop"

import CloudShape from "./SvgIcons/CloudShape"
import CloudShapeOutlined from "./SvgIcons/CloudShapeOutlined"
import PregnancyOracleCalendar from "./SvgIcons/PregnancyOracleCalendar"
import PregnancyOracleClock from "./SvgIcons/PregnancyOracleClock"
import PregnancyOracleStroller from "./SvgIcons/PregnancyOracleStroller"
import Print from "./SvgIcons/Print"

interface IIconProps extends SvgIconProps {
    name: string
    fill?: string
}

const custom = {
    disease: <Disease />,
    stethoscope: <Stethoscope />,
    medkit: <Medikit />,
    quiz: <Quiz />,
    pregnancyOracle: <PregnancyOracle />,
    cloudShape: <CloudShape />,
    cloudShapeOutlined: <CloudShapeOutlined />,
    pregnancyOracleClock: <PregnancyOracleClock />,
    pregnacyOracleStroller: <PregnancyOracleStroller />,
    pregnancOracleCalendar: <PregnancyOracleCalendar />,
    start: <Start />,
    stop: <Stop />,
    print: <Print />
}

const mui = {
    timeSpan: <UpdateOutlinedIcon />,
    radioButtonOutlined: <RadioButtonCheckedOutlinedIcon />,
    downloadOutlined: <DownloadOutlinedIcon />,
    playCircleOutlined: <PlayCircleFilledWhiteOutlinedIcon />,
    pauseCircleOutlined: <PauseCircleOutlineOutlinedIcon />,
    calendarMonthOutlined: <CalendarMonthOutlinedIcon />,
    childFriendlyOutlined: <ChildFriendlyOutlinedIcon />,
    accessAlarm: <AccessAlarmOutlinedIcon />,
    add: <AddIcon />,
    arrowForwardCircleOutlined: <ArrowCircleRightOutlinedIcon />,
    arrowForward: <ArrowForwardIcon />,
    arrowForwardIos: <ArrowForwardIosIcon />,
    arrowUpward: <ArrowUpward />,
    articleOutlined: <ArticleOutlinedIcon />,
    bugReport: <BugReportIcon />,
    check: <CheckIcon />,
    circleOutlined: <CircleOutlinedIcon />,
    clear: <ClearIcon />,
    close: <CloseIcon />,
    code: <CodeIcon />,
    contentCopy: <ContentCopyIcon />,
    descriptionOutlined: <DescriptionOutlinedIcon />,
    download: <CloudDownloadIcon />,
    errorOutline: <ErrorOutlineIcon />,
    expandMore: <ExpandMore />,
    externalLink: <LaunchIcon />,
    helpOutlined: <HelpOutlinedIcon />,
    info: <InfoIcon />,
    language: <LanguageIcon />,
    link: <Link />,
    localOffer: <LocalOfferIcon />,
    menu: <MenuIcon />,
    pdf: <PictureAsPdf />,
    playlistAddCheckOutlined: <PlaylistAddCheckOutlinedIcon />,
    search: <SearchIcon />,
    settingsApplications: <SettingsApplicationsIcon />,
    storage: <StorageIcon />,
    warning: <WarningIcon />,
    quizOutlined: <QuizOutlinedIcon />,
    authourizedPerson: <HowToRegOutlinedIcon />,
    home: <HomeIcon />
}

const customIcons: {
    [key: string]: JSX.Element
} = {
    ...custom,
    ...mui
}

export default function Icon({ name, fill, ...props }: IIconProps) {
    const customIcon = customIcons[name]
    if (!customIcon) return null

    return (
        <SvgIcon
            {...props}
            fill={fill}>
            {customIcon}
        </SvgIcon>
    )
}
