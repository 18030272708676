// eslint-disable-next-line no-restricted-imports
import { ImageProps } from "next/future/image"
import { createContext, useContext, useState } from "react"

import Alert from "@mui/material/Alert"
import Fab from "@mui/material/Fab"
import Tooltip from "@mui/material/Tooltip"
import { styled } from "@mui/material/styles"

import Icon from "Components/Icon/Icon"

interface IDownLoadImageProps extends Omit<ImageProps, "alt"> {
    children: JSX.Element
}

export const ImageDownloadContext = createContext<any>(false)

//use translations when they are working!
const downloadFailed = "Bildet kunne ikke lastes ned"
const downloadSuccess = "Bildet ble lastet ned"

export default function DownloadImageWrapper({
    src,
    title,
    children
}: IDownLoadImageProps) {
    const [imageIsDownloaded, setImageIsDownloaded] = useState<
        undefined | boolean
    >(undefined)

    const { showImageDownload } = useContext(ImageDownloadContext)
    if (showImageDownload === false) return null

    //function to download the image from the url
    function download(filename: string, url: string) {
        const element = document.createElement("a")
        element.setAttribute("href", url)
        element.setAttribute("download", filename)
        element.style.display = "none"
        document.body.appendChild(element)

        element.click()

        document.body.removeChild(element)
    }

    async function handleDownload() {
        try {
            const result = await fetch(src as string, {
                method: "GET",
                headers: {}
            })
            const blob = await result.blob()
            const url = URL.createObjectURL(blob)
            download(title as string, url)
            URL.revokeObjectURL(url)
            setImageIsDownloaded(true)
        } catch (error) {
            setImageIsDownloaded(false)
        }
        setTimeout(() => setImageIsDownloaded(undefined), 3000)
    }

    return (
        <StyledSpan>
            <Tooltip title="Last ned bilde">
                <DownloadFab
                    size="small"
                    color="error"
                    onClick={() => handleDownload()}
                    aria-label="Download">
                    <Icon name="download" />
                </DownloadFab>
            </Tooltip>
            {imageIsDownloaded !== undefined && (
                <Alert severity={imageIsDownloaded ? "success" : "error"}>
                    {imageIsDownloaded ? downloadSuccess : downloadFailed}
                </Alert>
            )}

            {children}
        </StyledSpan>
    )
}

const StyledSpan = styled("span")({
    position: "relative",
    display: "inline-flex",
    flexDirection: "column"
})

const DownloadFab = styled(Fab)(({ theme }) => ({
    position: "absolute",
    top: theme.spacing(1),
    left: theme.spacing(1)
}))
