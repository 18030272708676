export default function Start() {
    return (
        <svg
            viewBox="0 0 43 43"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.5003 7.16663C13.5991 7.16663 7.16699 13.5987 7.16699 21.5C7.16699 29.4012 13.5991 35.8333 21.5003 35.8333C29.4016 35.8333 35.8337 29.4012 35.8337 21.5C35.8337 13.5987 29.4016 7.16663 21.5003 7.16663ZM17.0212 29.5625V13.4375L29.5628 21.5L17.0212 29.5625Z"
                fill="white"
            />
            <path
                d="M21.5002 3.58331C11.6102 3.58331 3.5835 11.61 3.5835 21.5C3.5835 31.39 11.6102 39.4166 21.5002 39.4166C31.3902 39.4166 39.4168 31.39 39.4168 21.5C39.4168 11.61 31.3902 3.58331 21.5002 3.58331ZM21.5002 35.8333C13.5989 35.8333 7.16683 29.4012 7.16683 21.5C7.16683 13.5987 13.5989 7.16665 21.5002 7.16665C29.4014 7.16665 35.8335 13.5987 35.8335 21.5C35.8335 29.4012 29.4014 35.8333 21.5002 35.8333Z"
                fill="black"
            />
            <path
                d="M17.021 29.5625L29.5627 21.5L17.021 13.4375V29.5625Z"
                fill="black"
            />
        </svg>
    )
}
