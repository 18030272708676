export default function CloudShape() {
    return (
        <svg
            width="287"
            height="183"
            viewBox="0 0 287 183"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M204.764 167.438C225.032 167.67 247.04 171.288 264.204 160.53C283.164 148.644 288.803 123.046 285.723 100.914C281.071 67.5045 259.809 36.8082 230.133 20.6637C215.094 12.4833 198.348 7.96892 181.577 4.51108C166.025 1.30938 150.104 -1.05187 134.304 0.476939C99.6394 3.83072 69.6668 25.2341 36.7186 36.5121C30.5188 38.6332 24.0703 40.4582 18.7207 44.2522C8.16569 51.7441 4.00306 65.3033 1.86961 78.0541C-1.86794 100.442 -0.857359 125.231 12.8737 143.313C19.0254 151.413 27.3267 157.608 35.7081 163.379C45.6535 170.223 56.1042 176.73 67.774 179.868C84.4245 184.343 102.07 181.613 119.097 178.86C147.177 174.313 176.203 167.101 204.756 167.43" />
        </svg>
    )
}
