export default function Print() {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 4C7.59 4 4 7.59 4 12C4 16.41 7.59 20 12 20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4ZM11 10V6H13V10H16L12 14L8 10H11ZM17 17H7V15H17V17Z"
                fill="none"
            />
            <path
                d="M16 10H13V6H11V10H8L12 14L16 10Z"
                fill="black"
            />
            <path
                d="M17 15H7V17H17V15Z"
                fill="black"
            />
            <path
                d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                fill="black"
            />
        </svg>
    )
}
