export default function Disease() {
    return (
        <svg
            aria-label="Disease"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24">
            <path d="M20,6H16.6V5.57a3.06,3.06,0,0,0-3.06-3.05H10.46A3.06,3.06,0,0,0,7.4,5.57V6H4A2,2,0,0,0,2,8V19a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V8A2,2,0,0,0,20,6ZM9.4,5.57a1.05,1.05,0,0,1,1.06-1.05h3.08A1.05,1.05,0,0,1,14.6,5.57v.08H9.4ZM20,19H4V8H20Z" />
            <polygon points="11.22 15.94 12.98 15.94 12.98 14.02 14.97 14.02 14.97 12.25 12.98 12.25 12.98 10.16 11.22 10.16 11.22 12.25 9.2 12.25 9.2 14.02 11.22 14.02 11.22 15.94" />
        </svg>
    )
}
