export default function PregnancyOracle() {
    return (
        <svg
            viewBox="0 0 476 253"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2684_1497)">
                <path
                    d="M287.26 168.953C303.699 169.142 321.549 172.084 335.47 163.335C350.848 153.668 355.421 132.85 352.923 114.851C349.15 87.6793 331.905 62.7146 307.836 49.5845C295.639 42.9316 282.056 39.2601 268.454 36.4479C255.84 33.844 242.928 31.9236 230.113 33.167C201.998 35.8946 177.688 53.3015 150.965 62.4737C145.936 64.1988 140.706 65.683 136.367 68.7686C127.807 74.8617 124.43 85.8892 122.7 96.2591C119.669 114.467 120.488 134.627 131.625 149.333C136.614 155.921 143.347 160.959 150.145 165.653C158.211 171.218 166.688 176.511 176.153 179.063C189.657 182.702 203.969 180.482 217.779 178.242C240.553 174.545 264.096 168.68 287.254 168.947"
                    fill="#FFCCD5"
                />
                <path
                    d="M243.676 120.091L255.86 109.057L260.537 101.695L261.688 94.6708L259.782 89.3198L255.521 83.8061L247.598 81.4951L242.342 83.3374L237.997 85.0559L231.082 83.3374H225.273L217.792 85.9998L215.463 93.0043L214.435 100.79L220.114 108.497L226.548 114.74L234.321 121.263L237.997 123.437L243.676 120.091Z"
                    fill="white"
                />
                <path
                    d="M283.351 142.686C282.017 142.686 280.606 142.55 279.161 142.289C269.592 140.538 249.706 135.298 249.511 135.246C243.474 133.514 240.892 129.302 242.017 123.053C243.006 117.559 248.308 114.382 254.904 115.339L275.148 118.255L274.888 120.078L254.644 117.162C249.075 116.361 244.632 118.913 243.832 123.379C242.869 128.704 244.892 132.004 250.005 133.469C250.188 133.514 270.002 138.735 279.5 140.48C280.84 140.727 282.134 140.851 283.357 140.851C290.142 140.851 294.221 137.095 294.266 130.806C294.364 117.741 291.196 104.305 290.558 101.74C284.405 76.8862 268.734 66.2819 238.166 66.2819C223.601 66.2819 212.523 68.7621 204.3 73.8592C195.141 79.5422 189.098 88.6623 185.819 101.747C184.935 105.269 182.027 117.702 181.747 129.856C181.675 133.052 182.677 135.877 184.557 137.804C187.159 140.467 191.368 141.384 196.845 140.48C206.362 138.911 226.145 133.527 226.346 133.469C231.036 132.128 233.456 128.17 232.507 123.398C231.589 118.796 226.405 116.277 219.9 117.279L201.119 119.915L200.866 118.093L219.633 115.456C227.146 114.298 233.19 117.416 234.309 123.034C235.447 128.73 232.442 133.631 226.841 135.239C226.632 135.298 206.753 140.707 197.145 142.289C191.03 143.292 186.288 142.192 183.243 139.08C181.005 136.795 179.822 133.495 179.906 129.797C180.192 117.455 183.139 104.852 184.03 101.285C190.418 75.7926 207.111 64.4266 238.16 64.4266C269.209 64.4266 285.946 75.4476 292.341 101.285C292.985 103.882 296.198 117.52 296.101 130.806C296.049 138.13 291.163 142.68 283.351 142.68V142.686Z"
                    fill="#011520"
                    stroke="black"
                    strokeMiterlimit="10"
                />
                <path
                    d="M238.003 57.4677C222.261 57.4677 209.452 44.6501 209.452 28.8966C209.452 13.1431 222.261 0.325485 238.003 0.325485C253.746 0.325485 266.554 13.1431 266.554 28.8966C266.554 44.6501 253.746 57.4677 238.003 57.4677ZM238.003 2.16773C223.276 2.16773 211.293 14.1586 211.293 28.8966C211.293 43.6346 223.276 55.6255 238.003 55.6255C252.731 55.6255 264.713 43.6346 264.713 28.8966C264.713 14.1586 252.731 2.16773 238.003 2.16773Z"
                    fill="#011520"
                    stroke="black"
                    strokeMiterlimit="10"
                />
                <path
                    d="M237.997 124.127C237.431 124.127 236.891 123.964 236.429 123.659C235.343 122.949 229.618 119.154 224.251 114.083C217.2 107.43 213.628 101.402 213.628 96.168C213.628 88.0113 220.257 81.3779 228.408 81.3779C231.888 81.3779 235.343 82.6669 238.01 84.9453C240.703 82.6408 244.072 81.3779 247.605 81.3779C255.756 81.3779 262.384 88.0113 262.384 96.168C262.384 101.734 258.624 107.996 251.215 114.779C245.751 119.779 240.15 123.314 239.525 123.698C239.07 123.977 238.543 124.127 238.003 124.127H237.997ZM228.402 83.2267C221.265 83.2267 215.463 89.0334 215.463 96.1745C215.463 107.514 235.186 120.664 237.431 122.122C237.762 122.337 238.224 122.337 238.55 122.135C239.447 121.575 260.537 108.309 260.537 96.1745C260.537 89.0399 254.734 83.2267 247.598 83.2267C244.307 83.2267 241.041 84.5482 238.641 86.8591C238.283 87.2041 237.723 87.1976 237.366 86.8591C234.965 84.5547 231.7 83.2332 228.402 83.2332V83.2267Z"
                    fill="#011520"
                    stroke="black"
                    strokeMiterlimit="10"
                />
                <path
                    d="M238.602 175.45H237.405C236.865 175.45 236.429 175.014 236.429 174.473C236.429 173.933 236.865 173.497 237.405 173.497H238.602C239.142 173.497 239.578 173.933 239.578 174.473C239.578 175.014 239.142 175.45 238.602 175.45Z"
                    fill="#011520"
                    stroke="black"
                    strokeMiterlimit="10"
                />
                <path
                    d="M238.003 187.467C213.089 187.467 192.819 167.182 192.819 142.244H194.66C194.66 166.16 214.104 185.618 238.003 185.618C261.903 185.618 281.347 166.16 281.347 142.244H283.188C283.188 167.176 262.918 187.467 238.003 187.467Z"
                    fill="#011520"
                    stroke="black"
                    strokeMiterlimit="10"
                />
                <path
                    d="M222.931 98.6742C222.424 98.6742 222.007 98.2641 222.007 97.7499C222.007 93.2516 225.663 89.5932 230.158 89.5932C230.666 89.5932 231.082 90.0033 231.082 90.5176C231.082 91.0318 230.672 91.4419 230.158 91.4419C226.678 91.4419 223.848 94.2737 223.848 97.7564C223.848 98.2641 223.438 98.6807 222.925 98.6807L222.931 98.6742Z"
                    fill="#011520"
                    stroke="black"
                    strokeMiterlimit="10"
                />
                <path
                    d="M19.1771 232.058C18.429 232.058 18.2599 230.808 18.2599 230.138C18.2599 227.97 19.8472 226.297 22.5988 226.297C25.5196 226.297 27.2695 226.466 30.4375 226.629C32.5192 226.629 33.7746 227.794 33.7746 229.383C33.7746 231.882 32.6883 235.059 32.6883 240.397C32.6883 248.573 34.9391 249.823 34.9391 250.663C34.9391 251.412 34.191 252.082 33.1046 252.082C31.023 252.082 28.4339 250.917 27.5167 247.577C25.5131 250.832 22.2671 253 17.5118 253C6.75883 253 0 244.075 0 231.225C0 215.706 9.58857 204.444 21.597 204.444C28.4339 204.444 33.3518 208.448 33.3518 213.792C33.3518 217.633 31.0165 219.631 28.3494 219.631C27.1004 219.631 26.5995 219.215 26.5995 218.297C26.5995 217.294 27.1004 216.878 27.1004 215.126C27.1004 211.286 24.3487 208.701 20.7644 208.701C12.9257 208.701 6.3425 218.466 6.3425 230.144C6.3425 239.238 10.0114 248.248 18.7673 248.248C25.6042 248.248 27.2695 239.987 27.3541 231.811C25.6042 231.564 23.9324 231.394 23.0152 231.394C20.3481 231.394 19.678 232.065 19.1771 232.065V232.058Z"
                    fill="black"
                />
                <path
                    d="M47.6892 230.053C49.8554 225.047 53.108 220.875 57.8633 220.875C61.363 220.875 63.7829 223.127 63.7829 226.551C63.7829 229.721 61.363 231.811 59.0277 231.811C58.195 231.811 57.6096 231.479 57.6096 230.893C57.6096 230.307 57.8567 229.806 57.8567 228.973C57.8567 227.222 57.0241 226.134 55.6905 226.134C51.9371 226.134 46.6029 234.643 46.6029 246.913C46.6029 249.335 47.0192 250.5 47.0192 251.171C47.0192 252.173 46.3557 252.675 45.2693 252.675C42.8494 252.675 40.5986 250.168 40.5986 246.08C40.5986 242.493 42.1859 232.898 42.1859 229.396C42.1859 225.138 40.6832 224.136 40.6832 222.801C40.6832 222.053 41.4313 221.636 42.6022 221.636C45.523 221.636 47.4355 224.806 47.6892 230.066V230.053Z"
                    fill="black"
                />
                <path
                    d="M83.0445 247.408C81.4572 250.246 78.7056 252.583 74.4577 252.583C69.4553 252.583 64.2837 249.081 64.2837 240.15C64.2837 229.806 71.036 220.875 79.2065 220.875C83.5454 220.875 87.2923 223.374 87.2923 228.048C87.2923 228.966 87.1232 229.715 86.9606 230.301C88.125 230.385 88.7105 231.134 88.7105 233.555C88.7105 236.478 87.8778 238.392 87.8778 241.986C87.8778 249.83 91.7939 249.075 91.7939 250.409C91.7939 251.327 90.7921 252.245 89.0422 252.245C86.0434 252.245 83.9552 250.325 83.038 247.408H83.0445ZM83.0445 233.393C82.2118 233.393 81.6264 232.891 81.6264 232.227C81.6264 231.564 82.3745 230.808 82.3745 228.803C82.3745 226.304 80.7872 225.047 78.7901 225.047C74.1195 225.047 69.8716 231.642 69.8716 239.063C69.8716 245.071 72.6233 248.072 75.7067 248.072C79.3756 248.072 82.7127 244.069 83.0445 233.386V233.393Z"
                    fill="black"
                />
                <path
                    d="M95.8791 234.063V230.808C95.8791 224.136 94.3765 224.051 94.3765 223.049C94.3765 222.216 95.2091 221.63 96.959 221.63C99.457 221.63 101.792 224.299 101.792 228.387C101.792 231.388 101.708 232.475 101.708 235.398C101.708 245.911 103.789 248.333 105.708 248.333C108.206 248.333 113.131 242.656 113.131 232.898C113.131 225.64 110.795 224.39 110.795 223.387C110.795 221.968 112.962 220.888 114.549 220.888C117.301 220.888 118.719 224.644 118.719 229.233C118.719 237.741 112.129 252.596 104.544 252.596C100.127 252.596 95.8726 249.342 95.8726 234.076L95.8791 234.063Z"
                    fill="black"
                />
                <path
                    d="M124.983 228.055C124.983 223.381 123.897 222.88 123.897 222.3C123.897 221.467 124.814 220.881 126.147 220.881C129.146 220.881 130.981 223.218 130.981 226.388C130.981 229.559 129.393 238.067 129.393 242.324C129.393 250.253 132.646 249.413 132.646 250.5C132.646 251.587 131.56 252.583 129.81 252.583C126.141 252.583 123.89 249.582 123.89 242.988C123.89 239.818 124.976 231.225 124.976 228.055H124.983ZM128.236 216.122C125.731 216.122 124.736 214.619 124.736 212.705C124.736 210.036 126.401 207.614 129.075 207.614C131.241 207.614 132.659 209.033 132.659 211.533C132.659 214.287 130.408 216.122 128.242 216.122H128.236Z"
                    fill="black"
                />
                <path
                    d="M164.085 250.331C164.085 251.418 162.921 252.251 161.249 252.251C158.413 252.251 156.331 250.416 155.414 247.493C153.579 250.663 150.659 252.583 146.573 252.583C141.571 252.583 136.399 248.997 136.399 240.15C136.399 229.467 143.484 220.875 150.99 220.875C153.241 220.875 155.076 221.46 156.409 222.626C156.494 220.705 156.656 218.707 156.656 216.871C156.656 204.353 154.991 203.774 154.991 202.856C154.991 201.938 155.824 201.437 157.073 201.437C161.158 201.437 162.992 204.607 162.992 208.695C162.992 218.036 160.156 229.22 160.156 239.981C160.156 249.661 164.072 249.322 164.072 250.325L164.085 250.331ZM150.828 225.047C146.242 225.047 141.987 231.557 141.987 239.147C141.987 244.902 144.739 248.072 147.822 248.072C151.576 248.072 155.16 243.567 155.492 233.386H155.323C154.406 233.386 153.742 232.885 153.742 232.221C153.742 231.472 154.49 230.971 154.49 228.55C154.49 226.382 152.903 225.047 150.821 225.047H150.828Z"
                    fill="black"
                />
                <path
                    d="M169.92 228.055C169.92 223.381 168.834 222.88 168.834 222.3C168.834 221.467 169.751 220.881 171.085 220.881C174.084 220.881 175.918 223.218 175.918 226.388C175.918 229.559 174.331 238.067 174.331 242.324C174.331 250.253 177.584 249.413 177.584 250.5C177.584 251.587 176.497 252.583 174.747 252.583C171.078 252.583 168.828 249.582 168.828 242.988C168.828 239.818 169.914 231.225 169.914 228.055H169.92ZM173.173 216.122C170.669 216.122 169.673 214.619 169.673 212.705C169.673 210.036 171.339 207.614 174.012 207.614C176.178 207.614 177.597 209.033 177.597 211.533C177.597 214.287 175.346 216.122 173.18 216.122H173.173Z"
                    fill="black"
                />
                <path
                    d="M188.428 211.364C188.597 210.361 189.013 209.613 189.514 209.613C190.932 209.613 193.014 211.364 193.014 213.115C193.014 215.198 191.596 218.954 190.848 222.795C195.265 222.463 197.685 220.959 198.101 220.959C198.849 220.959 199.434 222.294 199.434 223.628C199.434 226.629 195.018 227.3 189.93 227.3C189.43 230.971 188.929 235.73 188.929 239.317C188.929 244.57 190.015 248.163 193.684 248.163C196.852 248.163 197.685 245.41 198.355 245.41C199.103 245.41 199.272 246.328 199.272 246.992C199.272 249.661 196.689 252.668 192.188 252.668C187.016 252.668 183.516 248.827 183.516 240.319C183.516 235.899 184.017 230.893 184.518 226.968C182.352 226.466 181.103 225.301 181.103 224.468C181.103 223.882 181.435 223.635 181.773 223.381C184.856 221.46 187.693 215.869 188.441 211.364H188.428Z"
                    fill="black"
                />
                <path
                    d="M201.106 238.985C201.106 229.057 207.774 220.881 216.029 220.881C220.947 220.881 224.785 223.967 224.785 229.057C224.785 237.735 214.032 240.241 206.941 240.488C207.527 244.407 209.608 248.163 214.526 248.163C221.363 248.163 223.113 242.07 223.783 242.07C223.952 242.07 225.032 242.402 225.032 244.075C225.032 246.243 221.864 252.753 213.524 252.753C205.185 252.753 201.1 246.998 201.1 238.985H201.106ZM206.772 237.149C214.357 236.901 219.613 233.894 219.613 229.474C219.613 226.72 217.694 225.054 215.196 225.054C211.026 225.054 207.11 230.392 206.772 237.149Z"
                    fill="black"
                />
                <path
                    d="M235.375 211.364C235.544 210.361 235.961 209.613 236.461 209.613C237.88 209.613 239.961 211.364 239.961 213.115C239.961 215.198 238.543 218.954 237.795 222.795C242.212 222.463 244.632 220.959 245.048 220.959C245.796 220.959 246.382 222.294 246.382 223.628C246.382 226.629 241.965 227.3 236.878 227.3C236.377 230.971 235.876 235.73 235.876 239.317C235.876 244.57 236.962 248.163 240.631 248.163C243.799 248.163 244.632 245.41 245.302 245.41C246.05 245.41 246.219 246.328 246.219 246.992C246.219 249.661 243.637 252.668 239.135 252.668C233.963 252.668 230.464 248.827 230.464 240.319C230.464 235.899 230.965 230.893 231.465 226.968C229.299 226.466 228.05 225.301 228.05 224.468C228.05 223.882 228.382 223.635 228.72 223.381C231.804 221.46 234.64 215.869 235.388 211.364H235.375Z"
                    fill="black"
                />
                <path
                    d="M253.713 240.234C253.713 240.651 253.544 241.068 253.544 241.901C253.544 245.488 255.964 248.658 260.049 248.658C263.302 248.658 265.305 246.738 265.305 244.238C265.305 237.396 250.714 238.978 250.714 229.389C250.714 224.885 254.298 220.881 260.55 220.881C265.136 220.881 269.39 223.635 269.39 227.554C269.39 229.721 267.725 231.056 266.053 231.056C265.806 231.056 265.383 230.887 265.305 230.555C265.136 226.466 262.053 224.8 259.802 224.8C257.551 224.8 255.717 226.388 255.717 228.387C255.717 234.395 270.555 233.061 270.555 243.157C270.555 248.58 266.3 252.753 259.385 252.753C252.47 252.753 248.548 248.749 248.548 243.906C248.548 241.322 250.551 239.486 252.633 239.486C253.381 239.486 253.719 239.733 253.719 240.234H253.713Z"
                    fill="black"
                />
                <path
                    d="M293.401 222.88C296.654 224.129 299.405 228.556 299.405 234.812C299.405 245.41 293.987 252.831 285.732 252.831C278.895 252.831 273.808 247.493 273.808 238.9C273.808 228.556 280.475 220.881 287.813 220.881C290.064 220.881 292.146 221.552 293.401 222.886V222.88ZM292.816 228.387C292.484 228.556 292.068 228.634 291.729 228.634C290.643 228.634 290.981 224.878 287.475 224.878C283.305 224.878 279.721 231.056 279.721 238.229C279.721 243.821 282.303 248.326 286.727 248.326C291.15 248.326 294.312 243.151 294.312 235.73C294.312 232.891 293.726 230.392 292.809 228.387H292.816Z"
                    fill="black"
                />
                <path
                    d="M311.414 230.053C313.58 225.047 316.833 220.875 321.588 220.875C325.088 220.875 327.508 223.127 327.508 226.551C327.508 229.721 325.088 231.811 322.752 231.811C321.92 231.811 321.334 231.479 321.334 230.893C321.334 230.307 321.581 229.806 321.581 228.973C321.581 227.222 320.749 226.134 319.415 226.134C315.662 226.134 310.327 234.643 310.327 246.913C310.327 249.335 310.744 250.5 310.744 251.171C310.744 252.173 310.08 252.675 308.994 252.675C306.574 252.675 304.323 250.168 304.323 246.08C304.323 242.493 305.91 232.898 305.91 229.396C305.91 225.138 304.408 224.136 304.408 222.801C304.408 222.053 305.156 221.636 306.327 221.636C309.248 221.636 311.16 224.806 311.414 230.066V230.053Z"
                    fill="black"
                />
                <path
                    d="M346.763 247.408C345.182 250.246 342.43 252.583 338.176 252.583C333.174 252.583 328.002 249.081 328.002 240.15C328.002 229.806 334.754 220.875 342.925 220.875C347.264 220.875 351.011 223.374 351.011 228.048C351.011 228.966 350.841 229.715 350.679 230.301C351.843 230.385 352.429 231.134 352.429 233.555C352.429 236.478 351.596 238.392 351.596 241.986C351.596 249.83 355.512 249.075 355.512 250.409C355.512 251.327 354.51 252.245 352.761 252.245C349.762 252.245 347.673 250.325 346.756 247.408H346.763ZM346.763 233.393C345.93 233.393 345.345 232.891 345.345 232.227C345.345 231.564 346.093 230.808 346.093 228.803C346.093 226.304 344.505 225.047 342.508 225.047C337.838 225.047 333.59 231.642 333.59 239.063C333.59 245.071 336.342 248.072 339.425 248.072C343.094 248.072 346.431 244.069 346.763 233.386V233.393Z"
                    fill="black"
                />
                <path
                    d="M378.449 225.633C374.11 225.633 366.363 237.982 366.363 249.582C366.363 251.835 365.862 252.583 364.528 252.583C361.861 252.583 359.272 250.162 359.272 243.991C359.272 236.817 361.354 226.05 361.354 214.456C361.354 204.945 359.766 203.858 359.766 202.856C359.766 202.022 360.684 201.437 361.933 201.437C365.849 201.437 367.852 204.607 367.852 208.61C367.852 216.956 364.437 228.803 363.936 241.315C367.104 229.135 374.195 220.536 379.861 220.536C383.946 220.536 385.95 222.958 385.95 226.714C385.95 231.72 379.861 236.556 375.86 238.392C377.024 241.979 381.194 247.486 385.364 247.486C386.197 247.486 386.528 247.07 386.782 247.07C387.199 247.07 387.53 247.74 387.53 248.652C387.53 250.572 385.449 252.655 382.697 252.655C376.276 252.655 370.858 243.144 370.858 239.304C370.858 237.8 371.189 237.051 372.438 236.381C376.107 234.46 380.524 231.205 380.524 227.873C380.524 226.453 379.607 225.62 378.443 225.62L378.449 225.633Z"
                    fill="black"
                />
                <path
                    d="M389.534 238.985C389.534 229.057 396.202 220.881 404.457 220.881C409.375 220.881 413.213 223.967 413.213 229.057C413.213 237.735 402.46 240.241 395.369 240.488C395.954 244.407 398.036 248.163 402.954 248.163C409.791 248.163 411.541 242.07 412.211 242.07C412.38 242.07 413.46 242.402 413.46 244.075C413.46 246.243 410.292 252.753 401.952 252.753C393.613 252.753 389.527 246.998 389.527 238.985H389.534ZM395.206 237.149C402.791 236.901 408.047 233.894 408.047 229.474C408.047 226.72 406.128 225.054 403.63 225.054C399.461 225.054 395.545 230.392 395.206 237.149Z"
                    fill="black"
                />
                <path
                    d="M427.888 250.331C427.888 251.581 426.639 252.583 424.805 252.583C421.39 252.583 418.469 249.498 418.469 241.986C418.469 232.807 420.388 225.464 420.388 214.371C420.388 204.945 418.885 203.611 418.885 202.856C418.885 202.022 419.802 201.437 421.051 201.437C424.636 201.437 426.971 203.774 426.971 208.695C426.971 218.121 424.05 229.305 424.05 240.234C424.05 249.999 427.888 249.081 427.888 250.331Z"
                    fill="black"
                />
                <path
                    d="M430.724 238.985C430.724 229.057 437.392 220.881 445.647 220.881C450.565 220.881 454.403 223.967 454.403 229.057C454.403 237.735 443.65 240.241 436.559 240.488C437.145 244.407 439.226 248.163 444.144 248.163C450.981 248.163 452.731 242.07 453.401 242.07C453.57 242.07 454.65 242.402 454.65 244.075C454.65 246.243 451.482 252.753 443.143 252.753C434.803 252.753 430.718 246.998 430.718 238.985H430.724ZM436.397 237.149C443.982 236.901 449.238 233.894 449.238 229.474C449.238 226.72 447.319 225.054 444.821 225.054C440.651 225.054 436.735 230.392 436.397 237.149Z"
                    fill="black"
                />
                <path
                    d="M464.993 211.364C465.162 210.361 465.579 209.613 466.08 209.613C467.498 209.613 469.579 211.364 469.579 213.115C469.579 215.198 468.161 218.954 467.413 222.795C471.83 222.463 474.25 220.959 474.666 220.959C475.414 220.959 476 222.294 476 223.628C476 226.629 471.583 227.3 466.496 227.3C465.995 230.971 465.494 235.73 465.494 239.317C465.494 244.57 466.581 248.163 470.249 248.163C473.417 248.163 474.25 245.41 474.92 245.41C475.668 245.41 475.837 246.328 475.837 246.992C475.837 249.661 473.255 252.668 468.753 252.668C463.582 252.668 460.082 248.827 460.082 240.319C460.082 235.899 460.583 230.893 461.084 226.968C458.917 226.466 457.668 225.301 457.668 224.468C457.668 223.882 458 223.635 458.339 223.381C461.422 221.46 464.258 215.869 465.006 211.364H464.993Z"
                    fill="black"
                />
            </g>
            <defs>
                <clipPath id="clip0_2684_1497">
                    <rect
                        width="476"
                        height="253"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}
