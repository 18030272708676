export default function Stop() {
    return (
        <svg
            viewBox="0 0 43 43"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.4998 7.16669C13.5807 7.16669 7.1665 13.5809 7.1665 21.5C7.1665 29.4192 13.5807 35.8334 21.4998 35.8334C29.419 35.8334 35.8332 29.4192 35.8332 21.5C35.8332 13.5809 29.419 7.16669 21.4998 7.16669ZM28.6665 28.6667H14.3332V14.3334H28.6665V28.6667Z"
                fill="white"
            />
            <path
                d="M21.5002 3.58331C11.6102 3.58331 3.5835 11.61 3.5835 21.5C3.5835 31.39 11.6102 39.4166 21.5002 39.4166C31.3902 39.4166 39.4168 31.39 39.4168 21.5C39.4168 11.61 31.3902 3.58331 21.5002 3.58331ZM21.5002 35.8333C13.581 35.8333 7.16683 29.4191 7.16683 21.5C7.16683 13.5808 13.581 7.16665 21.5002 7.16665C29.4193 7.16665 35.8335 13.5808 35.8335 21.5C35.8335 29.4191 29.4193 35.8333 21.5002 35.8333ZM28.6668 28.6666H14.3335V14.3333H28.6668V28.6666Z"
                fill="black"
            />
        </svg>
    )
}
