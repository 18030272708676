import locales from "./locales"

/**
 * Format date or date in string to be localized
 * @param value The date to format
 * @param locale Choose from predefined locales
 * @param options Extend toLocaleDateString as needed
 * @returns Local date formatting as string
 */
export function toLocalDate(
  value: string | Date,
  locale: keyof typeof locales,
  options?: Intl.DateTimeFormatOptions
) {
  return new Date(value).toLocaleDateString(locales[locale], {
    day: "numeric",
    month: "short",
    year: "numeric",
    ...options,
  })
}
