import {
    MixinsOptions,
    PaletteOptions,
    createTheme,
    responsiveFontSizes
} from "@mui/material/styles"

const theme = createTheme()

/**
 * Function that generates Theme mixins used for both useCategory = "public" and userCategory = "professional"
 * @param palette a palette object passed in based on the userCategory
 */

export const getMixins = (palette: PaletteOptions) => {
    const mixins = {
        lightFocusRing: {
            "&:focus": {
                outline: "none"
            },
            "&:focus-visible": {
                outline: `3px solid ${palette.grey?.[50]}`,
                boxShadow: `0 0 0 9px ${palette.secondary?.main}`,
                zIndex: 999
            }
        },
        darkFocusRing: {
            "&:focus": {
                outline: "none"
            },
            "&:focus-visible": {
                outline: `3px solid ${palette.grey?.[50]}`,
                boxShadow: `0 0 0 9px ${palette.secondary?.dark}`,
                zIndex: 999
            }
        },
        scroll: {
            "&::-webkit-scrollbar": {
                width: "4px"
            },
            "&::-webkit-scrollbar-track": {
                marginTop: "2px",
                backgroundColor: theme.palette.background?.default
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: palette.grey?.[500]
            }
        }
    }

    return mixins
}

/**
 * Function that generates Theme configurations used for both useCategory = "public" and userCategory = "professional"
 * @param palette a palette object passed in based on the userCategory
 * @param defaultMixins a default mixins object
 */

export const generateDefaultTheme = (
    palette: PaletteOptions,
    mixins: MixinsOptions
) => {
    const defaultTheme = createTheme({
        palette,
        shape: {
            borderRadius: 2
        },
        constants: {
            headerHeight: 104
        },

        typography: {
            allVariants: {
                color: palette.text?.primary
            },
            fontSize: 16,
            fontFamily: "Figtree",

            h1: {
                fontFamily: "IBMPlexSerifSemibold",
                fontSize: "3.125rem", //48PX
                fontWeight: 600,
                letterSpacing: "-0.025em",
                wordBreak: "break-word"
            },
            h2: {
                fontFamily: "IBMPlexSerifSemibold",
                fontSize: "1.75rem", //28PX
                fontWeight: 600,
                letterSpacing: "-0.025em",
                wordBreak: "break-word"
            },
            h3: {
                fontFamily: "IBMPlexSerifSemibold",
                fontSize: "1.25rem", //20PX
                fontWeight: 600,
                letterSpacing: "-0.025em",
                wordBreak: "break-word"
            },
            h4: {
                fontFamily: "FigtreeSemibold",
                fontSize: "1.25rem", //20PX
                fontWeight: 600,
                wordBreak: "break-word"
            },
            h5: {
                fontFamily: "FigtreeSemibold",
                fontSize: "1.125rem", //18PX
                fontWeight: 600,
                wordBreak: "break-word"
            },
            h6: {
                fontFamily: "FigtreeSemibold",
                fontSize: "1rem", //16PX
                fontWeight: 600,
                wordBreak: "break-word"
            },
            subtitle1: {
                fontFamily: "Figtree",
                fontSize: "1rem", //16PX
                fontWeight: 400
            },
            subtitle2: {
                fontFamily: "Figtree",
                fontSize: "0.875rem", //14PX
                fontWeight: 400
            },
            caption: {
                fontFamily: "FigtreeItalic",
                fontSize: "0.875rem", //14PX
                lineHeight: 1.43,
                fontWeight: 400,
                fontStyle: "italic",
                opacity: 0.85,
                letterSpacing: "0.03125em"
            },
            overline: {
                fontFamily: "IBMPlexSans",
                fontSize: "0.875rem", //14PX
                fontWeight: 400,
                letterSpacing: "0.125em",
                lineHeight: 1.43
            }
        },
        components: {
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        boxShadow: "none"
                    }
                }
            },
            MuiAccordionSummary: {
                styleOverrides: {
                    root: {
                        minHeight: 48,
                        maxHeight: 48,
                        backgroundColor: palette.grey?.[200],
                        "&.Mui-expanded": {
                            minHeight: 48,
                            maxHeight: 48
                        }
                    }
                }
            },
            MuiAccordionDetails: {
                styleOverrides: {
                    root: {
                        padding: theme.spacing(0, 2)
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: ({ ownerState }) => ({
                        fontFamily: "FigtreeSemibold",
                        textTransform: "none",
                        boxShadow: "none",
                        ...(ownerState.variant === "contained" && {
                            backgroundColor: palette.secondary?.main,
                            "&:focus": {
                                outline: `2px solid ${palette.primary?.dark} `,
                                outlineOffset: "2px"
                            },
                            "&:hover": {
                                backgroundColor: palette.secondary?.dark,
                                boxShadow: "none"
                            }
                        }),
                        ...(ownerState.variant === "outlined" && {
                            color: "black",
                            border: "2px solid",
                            borderColor: palette.secondary?.main,
                            backgroundColor: theme.palette.background?.default,
                            "&:focus": {
                                border: "2px solid",
                                borderColor: palette.secondary?.main,
                                backgroundColor: palette.secondary?.[50],
                                outline: `2px solid ${palette.primary?.dark} `,
                                outlineOffset: "2px"
                            },
                            "&:hover": {
                                border: "2px solid",
                                borderColor: palette.secondary?.main,
                                backgroundColor: palette.secondary?.[50],
                                boxShadow: "none"
                            }
                        })
                    })
                }
            },

            MuiButtonBase: {
                styleOverrides: {
                    root: mixins.lightFocusRing
                }
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        padding: 10
                    }
                }
            },
            MuiLink: {
                styleOverrides: {
                    root: mixins.lightFocusRing
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    root: mixins.lightFocusRing
                }
            },
            MuiList: {
                styleOverrides: {
                    root: mixins.scroll
                }
            },
            MuiListItem: {
                styleOverrides: {
                    button: mixins.darkFocusRing
                }
            },
            MuiTable: {
                styleOverrides: {
                    root: {
                        marginTop: "16px",
                        marginBottom: "16px"
                    }
                }
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        padding: 0
                    }
                }
            }
        },
        breakpoints: {
            values: {
                ...theme.breakpoints.values,
                xxl: 1800
            }
        }
    })

    return responsiveFontSizes(defaultTheme)
}
