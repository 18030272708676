/**
 * Check if link is non-navigational (mailto, tel, #, ?)
 * @param href link to check if is internal
 * @returns true if link is non-navigational
 */
export function isNonNavigational(href: string) {
  return ["#", "?", "mailto:", "tel:"].some((s) => href.startsWith(s))
}

/**
 * Check if link is internal (same root domain or navigational)
 * @param href link to check if is internal
 * @returns true if link is internal
 */
export function isInternalLink(href: string, hostname?: string | undefined) {
  if (!href) {
    return false
  }
  // Treat media as external links (without a dialog)
  if (href.startsWith("/media")) {
    return false
  }

  if (href.startsWith("/")) {
    return true
  }

  if (isNonNavigational(href)) {
    return true
  }

  // Treat same root domain URLs as internal
  const hrefRootDomain = extractRootDomain(href)

  if (typeof window !== "undefined") {
    const _hostname = hostname || window.location.hostname
    return hrefRootDomain === _hostname
  }

  return false
}

/**
 * Source: https://stackoverflow.com/a/23945027
 * Warning: you can use this function to extract the "root" domain, but it will not be as accurate as using the psl package (https://www.npmjs.com/package/psl).
 * @param url URL to extract domain from: https://www.example.com?foo=bar
 * @returns domain: example.com
 */
export function extractRootDomain(url: string) {
  if (isNonNavigational(url)) {
    return ""
  }

  let domain = extractHostname(url)
  const splitArr = domain.split(".")
  const arrLen = splitArr.length

  //extracting the root domain here
  //if there is a subdomain
  if (arrLen > 2) {
    domain = splitArr[arrLen - 2] + "." + splitArr[arrLen - 1]
    //check to see if it's using a Country Code Top Level Domain (ccTLD) (i.e. ".me.uk")
    if (splitArr[arrLen - 2].length == 2 && splitArr[arrLen - 1].length == 2) {
      //this is using a ccTLD
      domain = splitArr[arrLen - 3] + "." + domain
    }
  }
  return domain
}

function extractHostname(url: string) {
  let hostname = ""
  //find & remove protocol (http, ftp, etc.) and get hostname

  if (url.indexOf("//") > -1) {
    hostname = url.split("/")[2]
  } else {
    hostname = url.split("/")[0]
  }

  //find & remove port number
  hostname = hostname.split(":")[0]
  //find & remove "?"
  hostname = hostname.split("?")[0]

  return hostname
}
