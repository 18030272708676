export function isSessionStorageSupported() {
  try {
    const testKey = "__NHI_TEST_SESSIONSTORAGE_KEY__"
    sessionStorage.setItem(testKey, testKey)
    sessionStorage.removeItem(testKey)
    return true
  } catch (e) {
    return false
  }
}
