import { createContext, useContext, useEffect, useState } from "react"

import { getCookie } from "../../lib/getCookie"
import mutateData from "data/hooks/mutateData"
import { setUserCategory as setUserCategoryApi } from "data/internal/api/userCategory"
import {
    USER_CATEGORY_COOKIE_NAME,
    UserCategoryType
} from "data/internal/interfaces/userCategory"

export interface IUserCategoryContext {
    userCategory: UserCategoryType | undefined
    setUserCategory: (value: UserCategoryType) => void
}

export const UserCategoryContext = createContext<IUserCategoryContext>({
    userCategory: "layman",
    setUserCategory: () => void 0
})

export function UserCategoryProvider({
    children
}: {
    children: React.ReactNode
}) {
    const [state, setState] = useState<UserCategoryType | undefined>("layman")

    useEffect(() => {
        const cookie = getCookie(USER_CATEGORY_COOKIE_NAME)

        setState(cookie as UserCategoryType)
    }, [state])

    const value: IUserCategoryContext = {
        userCategory: state,
        setUserCategory: (category: UserCategoryType) => {
            mutateData("userCategory", setUserCategoryApi(category))
            setState(category)
        }
    }

    return (
        <UserCategoryContext.Provider value={value}>
            {children}
        </UserCategoryContext.Provider>
    )
}

/**
 * Returns an error if user context is undefined
 * @returns either throws an error or returns user context
 */

export default function useUserCategory() {
    const context = useContext<IUserCategoryContext>(UserCategoryContext)

    if (context === undefined)
        throw new Error(
            "useUserCategory must be used within a UserCategoryProvider"
        )

    return context
}
