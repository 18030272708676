export default function Stethoscope() {
    return (
        <svg
            aria-label="Stethoscope"
            viewBox="0 0 113 77"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g>
                <path
                    opacity={0.3}
                    d="M80.7612 66.1622C88.7556 66.2525 97.4448 67.6824 104.203 63.428C111.683 58.7334 113.907 48.6195 112.682 39.8755C110.849 26.6747 102.464 14.5485 90.7577 8.15864C84.8282 4.9268 78.2203 3.14136 71.6125 1.77699C65.4806 0.511052 59.1983 -0.410378 52.968 0.18568C39.2929 1.51177 27.4748 9.96596 14.4807 14.42C12.0329 15.2594 9.48933 15.9812 7.38337 17.4796C3.22067 20.438 1.57691 25.7943 0.737259 30.8335C-0.736916 39.6759 -0.337571 49.4726 5.07776 56.6171C7.50372 59.8161 10.7775 62.266 14.0842 64.5463C18.0089 67.2532 22.1306 69.8206 26.7309 71.0619C33.2949 72.831 40.2583 71.7509 46.97 70.6627C58.0468 68.8691 69.4929 66.0146 80.7557 66.1485"
                    fill="currentColor"
                />
                <g clipPath="url(#clip0_5_34)">
                    <g>
                        <mask
                            style={{
                                maskType: "luminance"
                            }}
                            maskUnits="userSpaceOnUse"
                            x={29}
                            y={9}
                            width={54}
                            height={67}>
                            <g>
                                <path
                                    d="M82.7365 9.31658H29.2674V75.7267H82.7365V9.31658Z"
                                    fill="white"
                                />
                            </g>
                        </mask>
                        <g mask="url(#mask0_5_34)">
                            <g>
                                <path
                                    d="M49.8218 12.6058C52.1538 11.7552 54.7754 12.3814 56.6947 13.9053C59.0419 15.7688 59.2841 18.6393 58.6292 21.3968C57.8721 24.603 56.5471 27.7037 55.436 30.8006C54.4404 33.5957 53.6245 36.5588 52.3184 39.2313C50.9253 42.083 48.6236 43.8238 45.3472 43.771C44.1281 43.7503 44.1281 45.6363 45.3472 45.6571C48.565 45.7099 51.3701 44.2878 53.1475 41.6039C54.9249 38.9201 55.754 35.5365 56.8234 32.5301L58.8866 26.7362C59.5112 24.9821 60.187 23.23 60.5637 21.4005C61.1997 18.3017 60.7984 15.02 58.3282 12.819C55.9357 10.6915 52.3998 9.66359 49.3183 10.7877C48.1826 11.2007 48.6748 13.0245 49.8218 12.6058C52.1538 11.7552 54.7754 12.3814 56.6947 13.9053C59.0419 15.7688 59.2841 18.6393 58.6292 21.3968C57.8721 24.603 56.5471 27.7037 55.436 30.8006C54.4404 33.5957 53.6245 36.5588 52.3184 39.2313C50.9253 42.083 48.6236 43.8238 45.3472 43.771C44.1281 43.7503 44.1281 45.6363 45.3472 45.6571C48.565 45.7099 51.3701 44.2878 53.1475 41.6039C54.9249 38.9201 55.754 35.5365 56.8234 32.5301L58.8866 26.7362C59.5112 24.9821 60.187 23.23 60.5637 21.4005C61.1997 18.3017 60.7984 15.02 58.3282 12.819C55.9357 10.6915 52.3998 9.66359 49.3183 10.7877C48.1826 11.2007 48.6748 13.0245 49.8218 12.6058Z"
                                    fill="#1D1D1B"
                                />
                                <path
                                    d="M44.4158 45.2157C43.3596 51.1983 42.294 57.4298 43.9578 63.4105C45.3528 68.4238 48.6539 72.5032 53.5299 74.4761C58.103 76.326 63.3102 76.0847 67.7527 73.993C72.1952 71.9013 75.8162 68.0202 77.8491 63.5709C80.5843 57.5845 80.6543 50.7457 78.894 44.4688C78.5665 43.3014 76.7399 43.7974 77.0693 44.9705C79.7363 54.4725 77.603 65.7171 68.7559 71.2923C64.9134 73.7103 60.2609 74.4985 55.8638 73.2783C51.321 72.0298 47.8571 68.7762 46.2519 64.3744C44.0524 58.3389 45.1578 51.8546 46.2406 45.7155C46.4507 44.5292 44.626 44.0181 44.4158 45.2157Z"
                                    fill="#1D1D1B"
                                />
                                <path
                                    d="M40.8043 10.7878C38.1051 9.80325 34.98 10.4671 32.6821 12.108C30.0813 13.9752 28.9721 16.8232 29.3337 19.9804C29.7652 23.7431 31.4764 27.3983 32.7408 30.9403C33.4273 32.8741 34.115 34.806 34.804 36.7361C35.2734 38.0563 35.7409 39.3766 36.4015 40.6214C38.1051 43.8277 41.1015 45.7137 44.7811 45.6477C45.9964 45.627 46.0002 43.7409 44.7811 43.7617C41.9022 43.8088 39.6288 42.4754 38.2187 39.9895C36.8085 37.5037 36.0722 34.6596 35.1295 32.0115C34.009 28.8675 32.7578 25.7385 31.8095 22.5379C31.0126 19.8446 30.6265 16.6572 32.7767 14.4769C34.6942 12.5324 37.6981 11.6384 40.3046 12.5908C41.4403 13.0077 41.9438 11.1857 40.8081 10.7727L40.8043 10.7878Z"
                                    fill="#1D1D1B"
                                />
                                <path
                                    d="M41.3135 11.644C41.2813 12.2098 40.4238 12.2098 40.4238 11.644C40.4238 11.0782 41.2813 11.0782 41.3135 11.644C41.3778 12.8511 43.2707 12.8586 43.2063 11.644C43.1382 10.3841 42.1879 9.31658 40.8686 9.31658C40.2587 9.32095 39.6745 9.56293 39.2413 9.99079C38.808 10.4186 38.5598 10.9984 38.55 11.6062C38.5401 12.2139 38.7692 12.8014 39.1884 13.243C39.6075 13.6847 40.1834 13.9454 40.7929 13.9695C42.1426 14.0185 43.1363 12.9321 43.2063 11.644C43.2707 10.4312 41.3778 10.435 41.3135 11.644Z"
                                    fill="#1D1D1B"
                                />
                                <path
                                    d="M49.4432 11.644C49.413 12.2098 48.5555 12.2098 48.5555 11.644C48.5555 11.0782 49.413 11.0782 49.4432 11.644C49.5076 12.8511 51.4024 12.8586 51.3361 11.644C51.268 10.3841 50.3196 9.31658 48.9984 9.31658C48.3884 9.32095 47.8043 9.56293 47.3711 9.99079C46.9378 10.4186 46.6896 10.9984 46.6798 11.6062C46.6699 12.2139 46.899 12.8014 47.3181 13.243C47.7373 13.6847 48.3132 13.9454 48.9227 13.9695C50.2723 14.0185 51.266 12.9321 51.3361 11.644C51.3948 10.4312 49.5019 10.435 49.4432 11.644Z"
                                    fill="#1D1D1B"
                                />
                                <path
                                    d="M76.4124 44.2802C79.3835 44.2802 81.7919 41.8804 81.7919 38.92C81.7919 35.9597 79.3835 33.5599 76.4124 33.5599C73.4414 33.5599 71.033 35.9597 71.033 38.92C71.033 41.8804 73.4414 44.2802 76.4124 44.2802Z"
                                    fill="white"
                                />
                                <path
                                    d="M80.8436 38.9183C80.7868 41.3287 78.8731 43.3355 76.4124 43.3355C75.5356 43.3358 74.6783 43.077 73.949 42.5919C73.2198 42.1068 72.6513 41.4171 72.3155 40.61C71.9797 39.8029 71.8916 38.9147 72.0625 38.0577C72.2333 37.2008 72.6553 36.4135 73.2752 35.7956C73.8952 35.1776 74.685 34.7567 75.545 34.5862C76.405 34.4156 77.2964 34.5029 78.1066 34.8372C78.9167 35.1715 79.6092 35.7376 80.0964 36.4641C80.5836 37.1905 80.8436 38.0446 80.8436 38.9183C80.8739 40.131 82.7667 40.1348 82.7364 38.9183C82.7373 37.6223 82.3372 36.3575 81.5907 35.2963C80.8442 34.2351 79.7875 33.4289 78.5646 32.9875C77.3417 32.5463 76.012 32.4913 74.7566 32.8301C73.5012 33.169 72.3811 33.8852 71.5489 34.8812C70.7167 35.8772 70.2129 37.1046 70.106 38.3962C69.9992 39.6878 70.2945 40.9808 70.9517 42.0992C71.6089 43.2175 72.5962 44.1068 73.7789 44.6459C74.9617 45.1851 76.2824 45.3478 77.5614 45.1121C80.5748 44.5934 82.6721 41.9058 82.7364 38.9183C82.7667 37.7055 80.8739 37.7055 80.8436 38.9183Z"
                                    fill="#1D1D1B"
                                />
                                <path
                                    d="M76.4484 38.9182C76.4579 38.848 76.4399 38.7769 76.3983 38.7194C76.3567 38.662 76.2947 38.6226 76.2248 38.6093C76.155 38.596 76.0828 38.6098 76.0228 38.648C75.9629 38.6861 75.9199 38.7456 75.9027 38.8144C75.8855 38.8831 75.8953 38.9558 75.9301 39.0175C75.9649 39.0793 76.0222 39.1253 76.0901 39.1464C76.158 39.1674 76.2313 39.1617 76.2952 39.1305C76.359 39.0993 76.4084 39.0449 76.4333 38.9786C76.4654 38.9277 76.4958 38.9032 76.4333 38.9447C76.3386 39.0069 76.4333 38.9578 76.4466 38.9447C76.492 38.9088 76.2989 38.9559 76.4087 38.9559C76.5185 38.9559 76.3708 38.971 76.3481 38.939C76.3481 38.939 76.4787 39.039 76.3822 38.939C76.2857 38.839 76.367 38.939 76.3822 38.9522C76.3973 38.9654 76.3708 38.8089 76.3708 38.9126C76.3708 39.0163 76.3708 38.7674 76.3481 38.9239C76.3481 38.8937 76.4617 38.8145 76.369 38.8881C76.3311 38.9163 76.4768 38.8786 76.4276 38.8711C76.2894 38.8503 76.5582 38.8711 76.3973 38.8522C76.4257 38.8522 76.5166 38.9786 76.4352 38.8748C76.4049 38.839 76.4503 39.0239 76.4447 38.9126C76.45 39.161 76.5514 39.3978 76.7277 39.5735C76.9041 39.7493 77.1418 39.8503 77.3911 39.8556C77.6421 39.8556 77.8828 39.7562 78.0603 39.5794C78.2378 39.4026 78.3375 39.1627 78.3375 38.9126C78.2807 37.8734 77.4971 36.9907 76.4087 36.9907C75.9065 36.9975 75.4268 37.1993 75.0715 37.553C74.7163 37.9068 74.5136 38.3846 74.5064 38.885C74.4993 39.3854 74.6883 39.8688 75.0333 40.2325C75.3783 40.5962 75.852 40.8115 76.3538 40.8326C77.4649 40.8797 78.2807 39.965 78.3375 38.9126C78.3375 38.6624 78.2378 38.4226 78.0603 38.2457C77.8828 38.0689 77.6421 37.9696 77.3911 37.9696C77.1401 37.9696 76.8994 38.0689 76.7219 38.2457C76.5444 38.4226 76.4447 38.6624 76.4447 38.9126L76.4484 38.9182Z"
                                    fill="#1D1D1B"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
            <defs>
                <clipPath>
                    <rect
                        width={54}
                        height={67}
                        fill="white"
                        transform="translate(29.002 9.02148)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}
