/* eslint-disable no-console */
import { CacheProvider, EmotionCache } from "@emotion/react"
import createEmotionCache from "createEmotionCache"
import { ThemeProvider } from "mui/ThemeProvider"
import { AppProps } from "next/app"
import { useRouter } from "next/router"
import React, { useEffect } from "react"
import "styles/global.css"

import { excludeMetatags } from "lib/excludeMetatags"

import { ImageDownloadContext } from "Components/Image/DownloadImageWrapper"
import { UserCategoryProvider } from "Components/UserCategoryModal/useUserCategory"

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

interface PortiaAppProps extends AppProps {
    emotionCache?: EmotionCache
}

export default function App(props: PortiaAppProps) {
    const {
        Component,
        emotionCache = clientSideEmotionCache,
        pageProps
    } = props

    const router = useRouter()
    useEffect(() => {
        if (excludeMetatags(router.pathname)) return

        const handleRouteChangeComplete = () => {
            try {
                window.loadAds()
            } catch {}
        }

        router.events.on("routeChangeComplete", handleRouteChangeComplete)

        return () => {
            router.events.off("routeChangeComplete", handleRouteChangeComplete)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.events])

    const [showImageDownload, setShowImageDownload] = React.useState(false)

    return (
        <CacheProvider value={emotionCache}>
            <UserCategoryProvider>
                <ImageDownloadContext.Provider
                    value={{ showImageDownload, setShowImageDownload }}>
                    <ThemeProvider>
                        <Component {...pageProps} />
                    </ThemeProvider>
                </ImageDownloadContext.Provider>
            </UserCategoryProvider>
        </CacheProvider>
    )
}
