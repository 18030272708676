/**
 * Function that checks if something (often a page/article) is published or not
 * @param published Published date
 * @param unpublished Eventual date of unpublishing
 * @returns True if published, false if unpublished/not published
 */
export function isPublished(published?: Date, unpublished?: Date) {
  // Article has not been published
  if (!published) {
    return false
  }

  // Article has published set in the future
  if (published && new Date(published).getTime() > Date.now()) {
    return false
  }

  // Article has been un-published
  if (unpublished && new Date(unpublished).getTime() < Date.now()) {
    return false
  }

  return true
}
