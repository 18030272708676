import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles"
import useUserCategory from "Components/UserCategoryModal/useUserCategory"
import defaultTheme, { cdsTheme } from "mui/theme"

export function ThemeProvider({ children }: { children: React.ReactNode }) {
    const { userCategory } = useUserCategory()
    const colorTheme = userCategory === "professional" ? cdsTheme : defaultTheme

    return (
        <MuiThemeProvider theme={colorTheme}>
            <CssBaseline />
            {children}
        </MuiThemeProvider>
    )
}
